import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useHistoryState } from "./ScrollTracker";

interface MetaProps {
  alternatePath: string;
  alternateLang: string;
  title: string | undefined;
  description: string | undefined;
  path: string | undefined;
}

// Change metadata for different sections dynamically if found and defined
const Meta: React.FC<MetaProps> = ({
  alternatePath,
  alternateLang,
  title,
  description,
  path,
}) => {
  const location = useLocation();
  const visibleUrl = useHistoryState(); // Get the visible URL from the custom hook

  // Render meta only if the current path matches the one from props meaning that this meta's section is active
  return visibleUrl === path || location.pathname === path ? (
    <Helmet>
      <link
        rel="alternate"
        href={`${window.location.protocol}//${window.location.host}${alternatePath}`}
        hrefLang={alternateLang}
      />
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
    </Helmet>
  ) : (
    <></>
  );
};

export default Meta;
