import { baseUrl } from "./constants";
import { resources } from "./i18n"; // Adjust the path as necessary

const en = resources.en.translation;
const fi = resources.fi.translation;

// For rich snippets the business info
export const businessSchemaEN = {
  "@context": "https://schema.org",
  "@type": "HealthAndBeautyBusiness",
  additionalType: "HealthClub",
  name: "Wellinyoga",
  employee: {
    "@type": "Person",
    name: "Dennis Wellingtone",
  },
  founder: {
    "@type": "Person",
    name: "Dennis Wellingtone",
  },
  paymentAccepted: "Card, mobile payment",
  url: `${baseUrl}`,
  logo: `${baseUrl}/images/wellinyoga-logo.webp`,
  image: `${baseUrl}/images/jooga-studio-tampere-Dennis-Wellingtone-wellinyoga.webp`,
  sameAs: [
    "https://www.instagram.com/wellinyoga",
    "https://www.facebook.com/denniswellingtoneyoga/",
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "Pirkankatu 11",
    addressLocality: "Tampere",
    addressRegion: "Pirkanmaa",
    postalCode: "33230",
    addressCountry: "FI",
  },
  telephone: "+358452745212",
  openingHoursSpecification: {
    "@type": "OpeningHoursSpecification",
    dayOfWeek: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    opens: null,
    closes: null,
  },
  description: "Open during weekly classes and by appointment.",
};

export const businessSchemaFI = {
  "@context": "https://schema.org",
  "@type": "HealthAndBeautyBusiness",
  additionalType: "HealthClub",
  name: "Wellinyoga",
  employee: {
    "@type": "Person",
    name: "Dennis Wellingtone",
  },
  founder: {
    "@type": "Person",
    name: "Dennis Wellingtone",
  },
  paymentAccepted: "Kortti, mobiilimaksu",
  url: `${baseUrl}`,
  logo: `${baseUrl}/images/wellinyoga-logo.webp`,
  image: `${baseUrl}/images/jooga-studio-tampere-Dennis-Wellingtone-wellinyoga.webp`,
  sameAs: [
    "https://www.instagram.com/wellinyoga",
    "https://www.facebook.com/denniswellingtoneyoga/",
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "Pirkankatu 11",
    addressLocality: "Tampere",
    addressRegion: "Pirkanmaa",
    postalCode: "33230",
    addressCountry: "FI",
  },
  telephone: "+358452745212",
  openingHoursSpecification: {
    "@type": "OpeningHoursSpecification",
    dayOfWeek: [
      "Maanantai",
      "Tiistai",
      "Keskiviikko",
      "Torstai",
      "Perjantai",
      "Lauantai",
    ],
    opens: null,
    closes: null,
  },
  description: "Auki viikkotuntien aikaan ja sovitusti.",
};

// For better indexing of the site
export const breadcrumbSchemaEN = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      item: {
        "@id": `${baseUrl}${en.paths.home}`,
        name: `${en.pageTitles.home}`,
      },
    },
    {
      "@type": "ListItem",
      position: 2,
      item: {
        "@id": `${baseUrl}${en.paths.services}`,
        name: `${en.pageTitles.services}`,
      },
    },
    {
      "@type": "ListItem",
      position: 3,
      item: {
        "@id": `${baseUrl}${en.paths.schedule}`,
        name: `${en.pageTitles.schedule}`,
      },
    },
    {
      "@type": "ListItem",
      position: 4,
      item: {
        "@id": `${baseUrl}${en.paths.pricing}`,
        name: `${en.pageTitles.pricing}`,
      },
    },
    {
      "@type": "ListItem",
      position: 5,
      item: {
        "@id": `${baseUrl}${en.paths.about}`,
        name: `${en.pageTitles.about}`,
      },
    },
    {
      "@type": "ListItem",
      position: 6,
      item: {
        "@id": `${baseUrl}${en.paths.whyMe}`,
        name: `${en.pageTitles.whyMe}`,
      },
    },
    {
      "@type": "ListItem",
      position: 7,
      item: {
        "@id": `${baseUrl}${en.paths.faq}`,
        name: `${en.pageTitles.faq}`,
      },
    },
    {
      "@type": "ListItem",
      position: 8,
      item: {
        "@id": `${baseUrl}${en.paths.testimonials}`,
        name: `${en.pageTitles.testimonials}`,
      },
    },
    {
      "@type": "ListItem",
      position: 9,
      item: {
        "@id": `${baseUrl}${en.paths.instagramFeed}`,
        name: `${en.pageTitles.instagramFeed}`,
      },
    },
    {
      "@type": "ListItem",
      position: 10,
      item: {
        "@id": `${baseUrl}${en.paths.contact}`,
        name: `${en.pageTitles.contact}`,
      },
    },
  ],
};

export const breadcrumbSchemaFI = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      item: {
        "@id": `${baseUrl}${fi.paths.home}`,
        name: `${fi.pageTitles.home}`,
      },
    },
    {
      "@type": "ListItem",
      position: 2,
      item: {
        "@id": `${baseUrl}${fi.paths.services}`,
        name: `${fi.pageTitles.services}`,
      },
    },
    {
      "@type": "ListItem",
      position: 3,
      item: {
        "@id": `${baseUrl}${fi.paths.schedule}`,
        name: `${fi.pageTitles.schedule}`,
      },
    },
    {
      "@type": "ListItem",
      position: 4,
      item: {
        "@id": `${baseUrl}${fi.paths.pricing}`,
        name: `${fi.pageTitles.pricing}`,
      },
    },
    {
      "@type": "ListItem",
      position: 5,
      item: {
        "@id": `${baseUrl}${fi.paths.about}`,
        name: `${fi.pageTitles.about}`,
      },
    },
    {
      "@type": "ListItem",
      position: 6,
      item: {
        "@id": `${baseUrl}${fi.paths.whyMe}`,
        name: `${fi.pageTitles.whyMe}`,
      },
    },
    {
      "@type": "ListItem",
      position: 7,
      item: {
        "@id": `${baseUrl}${fi.paths.faq}`,
        name: `${fi.pageTitles.faq}`,
      },
    },
    {
      "@type": "ListItem",
      position: 8,
      item: {
        "@id": `${baseUrl}${fi.paths.testimonials}`,
        name: `${fi.pageTitles.testimonials}`,
      },
    },
    {
      "@type": "ListItem",
      position: 9,
      item: {
        "@id": `${baseUrl}${fi.paths.instagramFeed}`,
        name: `${fi.pageTitles.instagramFeed}`,
      },
    },
    {
      "@type": "ListItem",
      position: 10,
      item: {
        "@id": `${baseUrl}${fi.paths.contact}`,
        name: `${fi.pageTitles.contact}`,
      },
    },
  ],
};

// For indexing the FAQ's on the page
export const faqPageSchemaEN = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "I don't know Finnish, will I be able to understand in the class?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The classes will be taught in English using Finnish if needed. The names of all asanas will be mentioned in Sanskrit as well.",
      },
    },
    {
      "@type": "Question",
      name: "I have never done yoga before. What is a good class to begin with?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Go Deep (intensity 2) is a good class to start. However, anyone can join any class. The private class is also a good way to start as then I will be able to focus more on your personal practise, alignment, breathwork and possible modifications of poses.",
      },
    },
    {
      "@type": "Question",
      name: "I am not flexible. Can I still do yoga?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yoga improves flexibility therefore you get flexibility from practising yoga! Also, there is a lot more to yoga then just stretching. You might have other strong points that are as important such as strength, balance or the ability to focus.",
      },
    },
    {
      "@type": "Question",
      name: "Can I do yoga if I am pregnant or sick?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "If you have flu, you shouldn't do any sports. Other physical sicknesses you can discuss with the teacher. There are different aspects of yoga that you can/cannot do based on the stage of the pregnancy. We recommend the class Happy Mom -prenatal yoga, to ensure a safe practise that fits your situation.",
      },
    },
    {
      "@type": "Question",
      name: "Does yoga help in relieving stress?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Through breathing exercises and focusing while practising yoga, one may get some clarity that may also help in stress management. However, a yoga teacher is not a psychologist therefore one has to consult a specialist according to their needs.",
      },
    },
  ],
};

export const faqPageSchemaFI = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "En osaa englantia, pärjäänkö tunnilla?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Tunnilla käytetään kielenä englantia ja tarvittaessa suomea. Jooga-asanoiden nimet käydään läpi myös sanskritiksi.",
      },
    },
    {
      "@type": "Question",
      name: "En ole käynyt aikaisemmin joogatunnilla. Mikä on hyvä tunti aloittelijalle?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Go Deep (intensiteetti 2) on hyvä ensimmäiseksi tunniksi. Jokaiselle tunnille voi kuitenkin tulla kuka tahansa. Joogan yksityistunti on myös hyvä tapa aloittaa, silloin käymme läpi juuri sinulle sopivat linjaukset ja mahdolliset vaihtoehdot.",
      },
    },
    {
      "@type": "Question",
      name: "En ole notkea, voinko silti harrastaa joogaa?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Jooga parantaa liikkuvuutta joten joogaharrastus tuo lisää notkeutta! Jooga on kuitenkin paljon enemmän kuin venyttelyä. Sinulla saattaa olla muita vahvuuksia, kuten voimaa, tasapainokykyä tai keskittymiskykyä, jotka ovat yhtä tärkeitä joogassa.",
      },
    },
    {
      "@type": "Question",
      name: "Voinko joogata jos olen raskaana tai minulla on joitain sairauksia?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Flunssassa ei suositella mitään liikuntaa. Muista fyysistä sairauksista voit keskustella opettajan kanssa. Raskauden eri vaiheissa on eri harjoituksia, joita voi ja ei voi tehdä. Suosittelemme Happy Mom -raskausjoogan yksityistuntia odottaville, jotta harjoitus on turvallinen ja sopii juuri sinun kehollesi.",
      },
    },
    {
      "@type": "Question",
      name: "Auttaako jooga stressin kanssa?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Hengitysharjoitukset ja keskittyminen harjoitukseen voivat auttaa mielen selkiyttämisessä. Siitä saattaa olla apua myös stressinhallintaan. Joogaopettaja ei ole kuitenkaan mielenterveyden ammattilainen, joka kannattaa pitää mielessä ja mahdollisesti hakea apua myös alan ammattilaisilta.",
      },
    },
  ],
};
