import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import theme from "./theme";
import { Helmet } from "react-helmet";
import {
  businessSchemaEN,
  businessSchemaFI,
  breadcrumbSchemaEN,
  breadcrumbSchemaFI,
  faqPageSchemaEN,
  faqPageSchemaFI,
} from "./schema";

import Home from "./pages/Home";
import Services from "./pages/Services";
import Schedule from "./pages/Schedule";
import Pricing from "./pages/Pricing";
import About from "./pages/About";
import FAQ from "./pages/FAQ";
import WhyMe from "./pages/WhyMe";
import Testimonials from "./pages/Testimonials";
import InstagramFeed from "./pages/InstagramFeed";
import WhatsAppButton from "./components/WhatsAppButton";

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import images from "./utils/images";
import ScrollTracker from "./components/ScrollTracker";
import Meta from "./components/Meta";
import { ClearLocalStorage } from "./components/ClearLocalStorage";
import { baseUrl } from "./constants";
import { getAlternateLang } from "./utils/utils";

interface MetaContent {
  title: string; // Title for the meta tag
  description: string; // Description for the meta tag
}

// Define the structure of the metaContent object
interface MetaContentMap {
  [key: string]: MetaContent;
}

// Define the section keys as a union type
type SectionKey =
  | "home"
  | "services"
  | "schedule"
  | "pricing"
  | "about"
  | "whyMe"
  | "faq"
  | "contact"
  | "testimonials"
  | "instagramFeed";

// For these homepage variations show canonical tag
const canonicalHomepage = ["/home", "/etusivu", "/home/", "/etusivu/"];

// Register ScrollToPlugin with GSAP
gsap.registerPlugin(ScrollToPlugin);

const App: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  // Set hreflang tags and alternate urls depending on the active language
  const alternateLang = getAlternateLang();
  const alternatePaths = i18n.getResource(
    alternateLang,
    "translation",
    "paths",
  ) as Record<string, string>;

  const [isProgrammaticScroll, setIsProgrammaticScroll] = useState(false); // Check for whether the scroll initiated programmatically
  const [headerHeight, setHeaderHeight] = useState(0); // State to store header height
  const headerRef = useRef<HTMLElement>(null); // Ref for the Header to calculate its height dynamically

  const metaContent = t("metaContent", {
    returnObjects: true,
  }) as MetaContentMap;

  // Create an object to hold all refs
  const sectionRefs = useRef({
    main: useRef<HTMLDivElement>(null),
    home: useRef<HTMLDivElement>(null),
    services: useRef<HTMLDivElement>(null),
    schedule: useRef<HTMLDivElement>(null),
    pricing: useRef<HTMLDivElement>(null),
    about: useRef<HTMLDivElement>(null),
    whyMe: useRef<HTMLDivElement>(null),
    faq: useRef<HTMLDivElement>(null),
    contact: useRef<HTMLDivElement>(null),
    testimonials: useRef<HTMLDivElement>(null),
    instagramFeed: useRef<HTMLDivElement>(null),
  }).current;

  const scrollFinished = () => {
    setTimeout(() => {
      setIsProgrammaticScroll(false); // Reset after scrolling completes
    }, 200);
  };

  const scrollToSection = useCallback(
    (sectionRef: React.RefObject<HTMLDivElement>) => {
      if (sectionRefs.main.current && sectionRef.current) {
        setIsProgrammaticScroll(true); // Indicate that the scroll is programmatic

        // Calculate the top position of the section relative to the viewport
        const sectionTop =
          sectionRef.current.getBoundingClientRect().top + window.scrollY;

        // Update header height before scrolling
        const headerHeightCurrent =
          headerRef.current?.offsetHeight || headerHeight;

        // Calculate the final scroll position including minus the headerHeight and scroll to that position
        gsap.to(window, {
          duration: 1.45,
          scrollTo: { y: sectionTop, offsetY: headerHeightCurrent },
          ease: "power2.inOut",
          onComplete: scrollFinished,
        });
      }
    },
    [sectionRefs.main, headerHeight],
  );

  const scrollToSectionWithPathKey = useCallback(() => {
    const pathKey =
      (Object.keys(sectionRefs) as SectionKey[]).find(
        (key) => t(`paths.${key}`) === location.pathname,
      ) || "home"; // Fallback to "home" if no match is found

    // Scroll to the appropriate section
    scrollToSection(sectionRefs[pathKey]);
  }, [location.pathname, scrollToSection, sectionRefs, t]);

  useEffect(() => {
    scrollToSectionWithPathKey();
  }, [scrollToSectionWithPathKey]);

  useEffect(() => {
    if (headerRef.current) {
      // Get the height of the header and set it in state
      setHeaderHeight(headerRef.current.offsetHeight);
    }
  }, []);

  // Handle navigating
  const handleNavClick = (path: string) => {
    navigate(path);
    scrollToSectionWithPathKey();
  };

  // Mapping of sections to components
  const sections = [
    {
      key: "home" as SectionKey,
      component: <Home />,
      path: t("paths.home"),
      title: metaContent.home?.title,
      description: metaContent.home?.description,
    },
    {
      key: "services" as SectionKey,
      component: <Services />,
      path: t("paths.services"),
      title: metaContent.services?.title,
      description: metaContent.services?.description,
    },
    {
      key: "schedule" as SectionKey,
      component: <Schedule />,
      path: t("paths.schedule"),
      title: metaContent.schedule?.title,
      description: metaContent.schedule?.description,
    },
    {
      key: "pricing" as SectionKey,
      component: <Pricing />,
      path: t("paths.pricing"),
      title: metaContent.pricing?.title,
      description: metaContent.pricing?.description,
    },
    {
      key: "about" as SectionKey,
      component: <About />,
      path: t("paths.about"),
      title: metaContent.about?.title,
      description: metaContent.about?.description,
    },
    {
      key: "whyMe" as SectionKey,
      component: <WhyMe />,
      path: t("paths.whyMe"),
      title: metaContent.whyMe?.title,
      description: metaContent.whyMe?.description,
    },
    {
      key: "faq" as SectionKey,
      component: <FAQ />,
      path: t("paths.faq"),
      title: metaContent.faq?.title,
      description: metaContent.faq?.description,
    },
    {
      key: "testimonials" as SectionKey,
      component: <Testimonials />,
      path: t("paths.testimonials"),
      title: metaContent.testimonials?.title,
      description: metaContent.testimonials?.description,
    },
    {
      key: "instagramFeed" as SectionKey,
      component: <InstagramFeed />,
      path: t("paths.instagramFeed"),
      title: metaContent.instagramFeed?.title,
      description: metaContent.instagramFeed?.description,
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <html lang={i18n.language} />
        <meta name="theme-color" content={theme.palette.primary.main} />
        <title>{metaContent.home?.title}</title>
        <meta name="description" content={metaContent.home?.description} />

        {/* Canonical tag for homepage */}
        {/* If on some homepage variation then show canonical URL tag */}
        {canonicalHomepage.includes(location.pathname) && (
          <link
            rel="canonical"
            href={`${window.location.protocol}//${window.location.host}/`}
          />
        )}

        <script type="application/ld+json">
          {JSON.stringify(
            i18n.language === "fi" ? businessSchemaFI : businessSchemaEN,
          )}
        </script>

        <script type="application/ld+json">
          {JSON.stringify(
            i18n.language === "fi" ? breadcrumbSchemaFI : breadcrumbSchemaEN,
          )}
        </script>

        {/* FAQ page schema */}
        <script type="application/ld+json">
          {JSON.stringify(
            i18n.language === "fi" ? faqPageSchemaFI : faqPageSchemaEN,
          )}
        </script>

        {/* Open Graph tags */}
        <meta property="og:title" content={metaContent.home?.title} />
        <meta
          property="og:description"
          content={metaContent.home?.description}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.wellinyoga.fi" />
        <meta property="og:image" content={images.logo} />

        {/* X tags */}
        <meta
          name="twitter:card"
          content={`${baseUrl}/images/jooga-studio-tampere-Dennis-Wellingtone-wellinyoga.webp`}
        />
        <meta name="twitter:title" content={metaContent.home?.title} />
        <meta
          name="twitter:description"
          content={metaContent.home?.description}
        />
        <meta name="twitter:image" content={images.logo} />
      </Helmet>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          maxWidth: "100vw",
          paddingTop: `${headerHeight}px`,
          backgroundColor: theme.palette.beige.main,
        }}
        ref={sectionRefs.main}
      >
        <Header
          ref={headerRef}
          isProgrammaticScroll={isProgrammaticScroll}
          handleNavClick={handleNavClick}
        />
        <ScrollTracker />

        <main>
          {sections.map(({ key, component, path, title, description }) => (
            <section
              ref={sectionRefs[key]}
              id={key}
              key={key}
              aria-label={description}
            >
              <Meta
                alternatePath={alternatePaths[key]}
                alternateLang={alternateLang}
                title={title}
                description={description}
                path={path}
              />
              {component}
            </section>
          ))}
        </main>

        <footer>
          <section
            ref={sectionRefs["contact"]}
            id={"contact"}
            key={"contact"}
            aria-label={metaContent.contact?.description}
          >
            <Meta
              alternatePath={alternatePaths["contact"]}
              alternateLang={alternateLang}
              title={metaContent.contact?.title}
              description={metaContent.contact?.description}
              path={t("paths.contact")}
            />
            <Footer />
          </section>
        </footer>

        <WhatsAppButton />
      </Box>
    </ThemeProvider>
  );
};

const AppWithRouter: React.FC = () => (
  <Router>
    <App />
    <Routes>
      <Route path="/test-new-content" element={<ClearLocalStorage />} />
    </Routes>
  </Router>
);

export default AppWithRouter;
